<template>
  <div class="row">
    <div class="col-6 col-xs" v-for="game in getGames" :key="game.id">
      <GameCard
        :title="game.name"
        :end-at="game.endsAt"
        :start-at="game.startsAt"
        :status="game.status"
        :user-count="game.userCount"
        :index="game.game_index"
        :playzoneSlug="playzoneInfo.slug"
      ></GameCard>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GameCard from "@/view/pages/Games/GameCard";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Games",
  components: {
    GameCard
  },
  computed: {
    ...mapGetters(["gamesInfo", "playzoneInfo"]),
    getGames() {
      const games = [];
      if (this.gamesInfo.length > 0) {
        this.gamesInfo.forEach(game => {
          let status = "Terminé";
          if (
            this.$moment(game.ends_at).isAfter() &&
            this.$moment(game.starts_at).isBefore()
          ) {
            status = "En cours";
          } else if (this.$moment(game.starts_at).isAfter()) {
            status = "À venir";
          }
          game.status = status;
          game.userCount = game.users.length;
          game.startsAt = this.$moment(game.starts_at).format("DD/MM/YY");
          game.endsAt = this.$moment(game.ends_at).format("DD/MM/YY");
          games.push(game);
        });
      }
      console.log(games);
      return games;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Tous les jeux" }]);
  }
};
</script>
