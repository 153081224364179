<template>
  <div class="card card-custom gutter-b">
    <div class="card-header game-header">
      <div class="card-title d-flex">
        <span><img src="/media/svg/icons/General/Clipboard.svg" alt=""/></span
        ><span>{{ startAt }} - {{ endAt }}</span>
      </div>
      <router-link :to="{ name: 'game', params: { pathMatch: playzoneSlug , index: index.toLowerCase() }}" class="card-toolbar flaticon-eye icon-2x text--white text--darken-2-3">&nbsp;</router-link>
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-start align-items-center">
        <img :src="imgUrl" alt="" />
        <div>
          <h3 class="game-name">
            {{ title }}
            <span class="small"
              ><span>{{ status }}</span></span
            >
          </h3>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <p
        class="d-flex justify-content-start align-items-center align-content-center footer-content"
      >
        <img src="/media/svg/icons/Communication/Group.svg" alt="" /><span
          >{{ userCount }} Joueur<span v-if="userCount > 1">s</span></span
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameCard",
  props: {
    title: {
      required: true,
      String
    },
    startAt: String,
    endAt: String,
    userCount: {
      Number,
      default: 0
    },
    imgUrl: {
      default: "/media/svg/icons/Devices/Gamepad2.svg",
      String
    },
    status: String,
    index: String,
    playzoneSlug: {
      required: true,
      String
    }
  },
  mounted(){
    console.log(this.playzoneSlug)
  }
};
</script>

<style lang="scss" scoped>
.card-custom {
  .game-header {
    background-color: #b8b8b8;
    min-height: 30px;

    .card-title {
      span:last-child {
        font-size: 1rem;
        margin-left: 1rem;
        font-weight: 300;
      }
    }
  }
  .card-body {
    img {
      width: 4rem;
      height: auto;
    }
    .game-name {
      margin-left: 1rem;
      .small {
        display: block;
        font-weight: 300;
        margin-top: 0.5rem;
      }
    }
  }
  .card-footer {
    padding: 0.5rem 2.5rem;

    .footer-content {
      & > span {
        margin-left: 10px;
        font-size: 1.2rem;
      }
    }
  }
}
</style>
